<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <validation-observer ref="editSchoolYearValidation">
                <b-form @submit.prevent>

                    <b-row>
                        <b-col><h3>{{ $t("general.school_year") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant='warning' @click="$router.push({name: 'faq'})">{{ $t('general.help')}}</b-button>
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.school_year')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_year'>{{$t('general.school_year')}}</label>
                                <b-form-input id='school_year' v-model="year.Name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.title')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='title'>{{$t('general.title')}}</label>
                                <b-form-input id='title' v-model="year.Title"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                :name="$t('general.subtitle')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='subtitle'>{{$t('general.subtitle')}}</label>
                                <b-form-input id='subtitle' v-model="year.Subtitle"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col><h5>{{ $t("general.possibleRegister") }}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <validation-provider
                                name="Prijava od"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='registerFrom'>Prijava od</label>
                                <b-form-datepicker id='registerFrom' v-model="year.StartDateRegister" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                name="Prijava do"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='registerTo'>Prijava do</label>
                                <b-form-datepicker id='registerTo' v-model="year.EndDateRegister" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col><h5>{{ $t("general.possibleSubmit") }}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <validation-provider
                                name="Oddaja od"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='submitFrom'>Oddaja od</label>
                                <b-form-datepicker id='submitFrom' v-model="year.StartDateTurnIn" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                name="Oddaja do"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='submitTo'>Oddaja do</label>
                                <b-form-datepicker id='submitTo' v-model="year.EndDateTurnIn" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>

            <b-row class="mt-3">
                <b-col>
                    <b-row>
                        <b-col><h3>{{ $t("general.awardOS") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant='warning' @click="addModalActive = true; selectedSchoolType = 1">{{ $t('general.add_award')}}</b-button>
                        </b-col>
                    </b-row>
                    <vue-good-table class="mt-1"
                                    ref="table2"
                                    :columns='columns'
                                    :rows='awardsOS'
                                    styleClass="vgt-table striped bordered condensed"
                                    :pagination-options="{ enabled: true, perPage: pageLength2}">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1" @click="editAward(props.row.ID); selectedSchoolType = 1" variant="warning"><feather-icon icon="EditIcon"/></b-button>
                                    <b-button @click="onDeleteButtonPressed(props.row.ID)" variant="danger"><feather-icon icon="Trash2Icon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table2) ? $refs.table2.currentPage : 0) * pageLength2 - (pageLength2 - 1) }} - {{ props.total - (($refs.table2) ? $refs.table2.currentPage : 0) * pageLength2 > 0 ? (($refs.table2) ? $refs.table2.currentPage : 0) * pageLength2 : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>
                                    <b-dropdown variant="none" no-caret>
                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength2}}) </span>
                                            <feather-icon icon="ChevronDownIcon" size="16" class="text-body align-middle mr-25"/>
                                        </template>
                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths2" @click.native="setPageLength2(length)">{{ length }}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength2"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18"/>
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col><h3>{{ $t("general.awardSS") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant='warning' @click="addModalActive = true; selectedSchoolType = 2">{{ $t('general.add_award')}}</b-button>
                        </b-col>
                    </b-row>
                    <vue-good-table class="mt-1"
                                    ref="table"
                                    :columns='columns'
                                    :rows='awardsSS'
                                    styleClass="vgt-table striped bordered condensed"
                                    :pagination-options="{ enabled: true, perPage: pageLength}">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1" @click="editAward(props.row.ID); selectedSchoolType = 2" variant="warning"><feather-icon icon="EditIcon"/></b-button>
                                    <b-button @click="onDeleteButtonPressed(props.row.ID)" variant="danger"><feather-icon icon="Trash2Icon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>
                                    <b-dropdown variant="none" no-caret>
                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon icon="ChevronDownIcon" size="16" class="text-body align-middle mr-25"/>
                                        </template>
                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">{{ length }}</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18"/>
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col class="text-right">
                    <b-button @click="validationForm" variant="primary">{{ $t('general.save') }}</b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-modal id="confirm-deletion-modal" :cancel-disabled="true" hide-footer >
            <div class="d-block text-center">
                <p>{{ $t('general.delete_award_confirmation')}}</p>
                <div>
                    <b-button variant="warning" @click="onRejectDeletion">{{ $t('general.no') }}</b-button>
                    <b-button variant="danger" class="ml-2" @click="onAcceptDeletion">{{ $t('general.yes') }}</b-button>
                </div>
            </div>
        </b-modal>

        <b-modal :title="$t('general.add_award')" v-model="addModalActive" centered no-close-on-backdrop :cancel-disabled="true" hide-footer>
            <validation-observer ref="addAwardValidation">
                <b-form @submit.prevent>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='name'>{{ $t('general.name') }}</label>
                                <b-form-input id='name' v-model="addName"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.points')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='points'>{{ $t('general.points') }}</label>
                                <b-form-input id='points' type="number" v-model.number="addPoints"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationFormAddAward" variant="primary">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-modal :title="$t('general.edit_award')" v-model="editModalActive" centered no-close-on-backdrop :cancel-disabled="true" hide-footer>
            <validation-observer ref="editAwardValidation">
                <b-form @submit.prevent>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='names'>{{ $t('general.name') }}</label>
                                <b-form-input id='names' v-model="award.Name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.points')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='pointss'>{{ $t('general.points') }}</label>
                                <b-form-input id='pointss' type="number" v-model.number="award.Points"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationFormEditAward" variant="primary">{{ $t('general.save') }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BFormInput, BButton, BFormDatepicker, BPagination, BDropdown, BDropdownItem, BModal} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    import {VueGoodTable} from 'vue-good-table'
    export default {
        components: {BCol,
                     BRow,
                     BOverlay,
                     BCard,
                     BForm,
                     BFormInput,
                     BButton,
                     BFormDatepicker,
                     BPagination,
                     BDropdown,
                     BDropdownItem,
                     BModal,
                     VueGoodTable,
                     ValidationProvider,
                     ValidationObserver},
        data() {
            return {
                dateFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
                id: 0,
                schoolYear: '',
                registerFrom: '',
                registerTo: '',
                submitFrom: '',
                submitTo: '',
                showLoader: false,
                years: [],
                year: {},
                selected_year: null,
                required,

                awardsOS: [],
                awardsSS: [],
                columns: [
                    {
                        field: 'Name',
                        label:  this.$t('general.name'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Points',
                        label: this.$t('general.points'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false,
                        hidden: false
                    }
                ],
                pageLength: 10,
                pageLength2: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                lengths2: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                awardId: 0,
                addModalActive: false,
                addName: '',
                addPoints: '',
                selectedSchoolType: 0,
                award: {},
                editModalActive: false
            }
        },
        methods: {
            onDeleteButtonPressed(awardId) {
                this.awardId = awardId
                this.$bvModal.show('confirm-deletion-modal')
            },
            onAcceptDeletion() {
                this.deleteAward()
            },
            onRejectDeletion() {
                this.awardId = 0
                this.$bvModal.hide('confirm-deletion-modal')
            },
            setPageLength2(length) {
                this.pageLength2 = length
                this.$refs.table2.perPageChanged({currentPerPage:this.pageLength2})
            },
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            deleteAward() {
                const thisIns  = this
                this.$bvModal.hide('confirm-deletion-modal')
                thisIns.showLoader = true

                thisIns.$http.delete(`/v1/admin/nagrada/${thisIns.awardId}`)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.award_successfully_deleted')}\n`)
                        setTimeout(() => {
                            thisIns.onLoadAwards()
                        }, 500)
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.delete_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get(`/v1/admin/solskoLeto/${thisIns.id}`)
                    .then(res => {
                        thisIns.year = res.data
                        thisIns.showLoader = false
                        thisIns.onLoadAwards()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            validationForm() {
                this.$refs.editSchoolYearValidation.validate().then((response) => {
                    if (response) {
                        this.onEditSchoolYear()
                    }
                })
            },
            onEditSchoolYear() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.year.Name,
                    Title: thisIns.year.Title,
                    Subtitle: thisIns.year.Subtitle,
                    StartDateRegister: thisIns.year.StartDateRegister,
                    EndDateRegister: thisIns.year.EndDateRegister,
                    StartDateTurnIn: thisIns.year.StartDateTurnIn,
                    EndDateTurnIn: thisIns.year.EndDateTurnIn
                }
                thisIns.$http.patch(`/v1/admin/solskoLeto/${thisIns.year.ID}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.schoolYearEdited')}`)
                        thisIns.showLoader = false
                        setTimeout(() => {
                            thisIns.$router.go(-1)
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            validationFormAddAward() {
                this.$refs.addAwardValidation.validate().then((response) => {
                    if (response) {
                        this.onAddAward()
                    }
                })
            },
            onAddAward() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.addName,
                    Points: thisIns.addPoints,
                    SchoolYearID: thisIns.id,
                    SchoolTypeID: thisIns.selectedSchoolType
                }
                thisIns.$http.put('/v1/admin/nagrada', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.award_successfully_added')}`)
                        thisIns.addName = ''
                        thisIns.addPoints = ''
                        thisIns.showLoader = false
                        thisIns.addModalActive = false
                        thisIns.onLoadAwards()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            onLoadAwards() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.awardsOS = []
                thisIns.awardsSS = []
                thisIns.$http.get('/v1/admin/nagrada')
                    .then(res => {
                        if (res.data !== null) {
                            for (const award of res.data) {
                                if (award.SchoolYearID === Number(thisIns.id) && award.SchoolTypeID === 1) thisIns.awardsOS.push(award)
                                else if (award.SchoolYearID === Number(thisIns.id) && award.SchoolTypeID === 2) thisIns.awardsSS.push(award)
                            }
                        }
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            editAward(awardId) {
                const thisIns = this
                thisIns.awardId = awardId
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/admin/nagrada/${awardId}`)
                    .then((response) => {
                        thisIns.award = response.data
                        thisIns.editModalActive = true
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            validationFormEditAward() {
                this.$refs.editAwardValidation.validate().then((response) => {
                    if (response) {
                        this.onEditAward()
                    }
                })
            },
            onEditAward() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.award.Name,
                    Points: thisIns.award.Points,
                    SchoolYearID: thisIns.id,
                    SchoolTypeID: thisIns.selectedSchoolType
                }

                thisIns.$http.patch(`/v1/admin/nagrada/${thisIns.awardId}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.award_successfully_edited')}`)
                        thisIns.editModalActive = false
                        thisIns.showLoader = false
                        thisIns.onLoadAwards()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.edit_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
            this.id = this.$route.params.school_year_id
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>